import React from 'react';
import './JumboTron.scss';

function JumboTron() {

  const clickMore = () => {
    const element = document.getElementById('about');
    let offset = 150
    if (window.outerWidth < 425) {
      offset = 50;
    }
    const y = element!.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  return (
    <div className="px-4 d-flex justify-content-center flex-column vh-100">
      <div className="col-lg-8 mx-auto">
        <span className="fs-1 fw-bold">Hi! 👋🏻 mein Name ist <span className="gradient-color">Jannis Fey</span><br/>und ich bin <span
          className="gradient-color">Software Entwickler</span><br/> 👨🏻‍💻 aus Dortmund.</span>
        <div>
          <button onClick={clickMore} className="btn btn-lg btn-outline-info gradient-color mt-4">Mehr zu mir</button>
        </div>
      </div>
    </div>
  );
}

export default JumboTron;
