import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function ProjectCastlepath() {

  const [open, setOpen] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(index: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index
    });
  }

  return (
    <div className="project rounded-5 border shadow p-4 mt-3 mb-5">
      <h2>Castlepath</h2>
      <p>Ein kleines Spiel bei dem du den richtigen Weg zur Burg finden musst ohne dich von Gegner erwischen zu
        lassen. Castlepath wurde im Rahmen der Code Competition "Der dynamische Dungeon" von it-talents.de
        entwickelt.</p>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1 flex-wrap align-items-center">
          <span className="badge rounded-pill bg-dark">JAVA</span>
          <span className="badge rounded-pill bg-dark">LIBGDX</span>
          <span className="badge rounded-pill bg-dark">GAME-DESIGN</span>
        </div>
        <div className={open ? "project__dropdown-button project__dropdown-button--open" : "project__dropdown-button"}
             onClick={() => setOpen(!open)}>
          <i className="fa-solid fa-angle-down fa-2xl"></i>
        </div>
      </div>
      <div className={open ? "project__collapsed project__collapsed--open" : "project__collapsed"}>
        <div className="mt-3 mb-0">
          <h5 className="ms-2">Items im Spiel</h5>
          <ul>
            <li>Herzen - bringen dir mehr leben</li>
            <li>Schild & Schwert - beschützen dein Leben und töten Gegner</li>
            <li>Fackel - hilft dir mehr zu sehen</li>
            <li>Kompass - zeigt dir den richtigen Weg an</li>
            <li>Blitz - zeigt dir für kurze Zeit das ganze Labyrinth</li>
            <li>Brücke - um dir deinen eigenen Weg zu bauen</li>
            <li>Trank - lässt dich schneller laufen</li>
          </ul>
          <h5 className="ms-2">Steuerung</h5>
          <ul>
            <li>Nach oben/unten/links/rechts bewegen mit den Pfeiltasten</li>
            <li>Item einlösen mit ENTER oder SPACE</li>
            <li>Brücken mit ENTER einlösen und das mit Pfeiltasten setzen</li>
            <li>Spiel pausieren mit ESC</li>
            <li>Musik ein/aus mit M</li>
            <li>Cheat: Mit L kann das Licht auf ein maximales Level gesetzt werden</li>
            <li>Cheat: Mit C kann man zu jeder Zeit den Kompass verwenden</li>
            <li>Cheat: Mit B kann man unendlich viele Brücken bauen.</li>
          </ul>
          <h5 className="ms-2">Algorithmen hinter dem Spiel</h5>
          <p className="mx-3">Das Labyrinth wird mithilfe des "Randomized Prim's Algorithm" aufgebaut, dadurch wird
            sichergestellt das
            jeder Punkt im Labyrinth erreicht werden kann. Somit existiert immer ein Weg zu Ausgang.
            <br/>Zusätzlich wurde ein Pathfinding Algorithmus eingebaut welcher durch den Kompass zu Einsatz kommt. Der
            Algorithmus dazu ist eine Mischung aus Breitensuche und dem A* Algorithmus.</p>
          <div className="d-flex gap-3 flex-wrap mb-3 px-2">
            <img alt="CastlePath Game" className="project__image project__image--wide"
                 src='/projects/castlepath/screen1.png'
                 onClick={() => openLightboxOnSlide(1)}/>
            <img alt="CastlePath Game" className="project__image project__image--wide"
                 src='/projects/castlepath/screen2.png'
                 onClick={() => openLightboxOnSlide(2)}/>
            <img alt="CastlePath Game" className="project__image project__image--wide"
                 src='/projects/castlepath/screen3.png'
                 onClick={() => openLightboxOnSlide(3)}/>
            <img alt="CastlePath Game" className="project__image project__image--wide"
                 src='/projects/castlepath/screen4.png'
                 onClick={() => openLightboxOnSlide(4)}/>
            <img alt="CastlePath Game" className="project__image project__image--wide"
                 src='/projects/castlepath/screen5.png'
                 onClick={() => openLightboxOnSlide(5)}/>
            <img alt="CastlePath Game" className="project__image project__image--wide"
                 src='/projects/castlepath/screen6.png'
                 onClick={() => openLightboxOnSlide(6)}/>
            <img alt="CastlePath Game" className="project__image project__image--wide"
                 src='/projects/castlepath/screen7.png'
                 onClick={() => openLightboxOnSlide(7)}/>
          </div>
          <FsLightbox
            toggler={lightboxController.toggler}
            slide={lightboxController.slide}
            sources={[
              '/projects/castlepath/screen1.png',
              '/projects/castlepath/screen2.png',
              '/projects/castlepath/screen3.png',
              '/projects/castlepath/screen4.png',
              '/projects/castlepath/screen5.png',
              '/projects/castlepath/screen6.png',
              '/projects/castlepath/screen7.png',
            ]}
          />
          <div className="mt-4 mb-1 mx-2">
            <a download href="https://jannisfey.de/cms/content/castlepath/castlepath.zip"
               className="btn btn btn-outline-info gradient-color"><i className="fa-solid fa-download me-1"></i> Spiel
              hier herunterladen</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCastlepath;
