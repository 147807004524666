import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function ProjectPakby() {

  const [open, setOpen] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(index: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index
    });
  }

  return (
    <div className="project rounded-5 border shadow p-4 mt-3 mb-5">
      <h2>Pakby</h2>
      <p>Pakyby ist ein Uni-Projekt mit dem Ziel private Fahrten als Transportmöglichkeit zu vermitteln. Dazu wurde
        ein Prototyp in Form einer Webanwendung entwickelt sowie ein Businessplan vorbereitet.</p>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1 flex-wrap align-items-center">
          <span className="badge rounded-pill bg-dark">HTML</span>
          <span className="badge rounded-pill bg-dark">JAVASCRIPT</span>
          <span className="badge rounded-pill bg-dark">BOOTSTRAP</span>
          <span className="badge rounded-pill bg-dark">PHP BACKEND</span>
          <span className="badge rounded-pill bg-dark">MYSQL</span>
        </div>
        <div className={open ? "project__dropdown-button project__dropdown-button--open" : "project__dropdown-button"}
             onClick={() => setOpen(!open)}>
          <i className="fa-solid fa-angle-down fa-2xl"></i>
        </div>
      </div>
      <div className={open ? "project__collapsed project__collapsed--open" : "project__collapsed"}>
        <div className="mt-3 mb-0">
          <ul>
            <li>Einstellen von Fahrten und Sendungen</li>
            <li>Anfragen akzeptiert, abgelehnt oder Preisvorschlag machen</li>
            <li>direkte Bezahlung der Fahrer-Gebühr</li>
            <li>Online Highscore</li>
            <li>Generierung von druckbaren Bestätigungen</li>
            <li>gegenseitiges Bewertungssystem</li>
            <li>Live-Trackings der Fahrt und QR-Code-Scanner per eigener Android-App</li>
          </ul>
          <div className="d-flex gap-3 flex-wrap mb-3 px-2">
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby01.png'
                 onClick={() => openLightboxOnSlide(1)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby02.png'
                 onClick={() => openLightboxOnSlide(2)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby03.png'
                 onClick={() => openLightboxOnSlide(3)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby04.png'
                 onClick={() => openLightboxOnSlide(4)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby05.png'
                 onClick={() => openLightboxOnSlide(5)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby06.png'
                 onClick={() => openLightboxOnSlide(6)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby07.png'
                 onClick={() => openLightboxOnSlide(7)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby08.png'
                 onClick={() => openLightboxOnSlide(8)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby09.png'
                 onClick={() => openLightboxOnSlide(9)}/>
            <img alt="Circles App" className="project__image project__image--square" src='/projects/pakby/pakby10.png'
                 onClick={() => openLightboxOnSlide(10)}/>
          </div>
          <FsLightbox
            toggler={lightboxController.toggler}
            slide={lightboxController.slide}
            sources={[
              '/projects/pakby/pakby01.png',
              '/projects/pakby/pakby02.png',
              '/projects/pakby/pakby03.png',
              '/projects/pakby/pakby04.png',
              '/projects/pakby/pakby05.png',
              '/projects/pakby/pakby06.png',
              '/projects/pakby/pakby07.png',
              '/projects/pakby/pakby08.png',
              '/projects/pakby/pakby09.png',
              '/projects/pakby/pakby10.png',
            ]}
          />
        </div>
      </div>

    </div>
  )
}

export default ProjectPakby;
