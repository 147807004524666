import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function ProjectGameOfLife() {

  const [open, setOpen] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(index: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index
    });
  }

  return (
    <div className="project rounded-5 border shadow p-4 mt-3 mb-5">
      <h2>Game of Life</h2>
      <p>Das Projekt wurde im Rahmen der Code Competition "Game of Life 2018" von it-talents.de entwickelt.</p>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1 flex-wrap align-items-center">
          <span className="badge rounded-pill bg-dark">JAVASCRIPT</span>
          <span className="badge rounded-pill bg-dark">JQUERY</span>
        </div>
        <div className={open ? "project__dropdown-button project__dropdown-button--open" : "project__dropdown-button"}
             onClick={() => setOpen(!open)}>
          <i className="fa-solid fa-angle-down fa-2xl"></i>
        </div>
      </div>
      <div className={open ? "project__collapsed project__collapsed--open" : "project__collapsed"}>
        <p className="mt-3">
          Das Spiel des Lebens ist ein vom Mathematiker John Horton Conway 1970 entworfenes Spiel, basierend auf einem
          zweidimensionalen zellulären Automaten. Es ist eine einfache und bis heute populäre Umsetzung der
          Automaten-Theorie von Stanisław Marcin Ulam.
          „Spiel“ versteht sich dabei nicht als Gesellschafts- oder Kampfspiel, sondern als ein Geschehen, das nach
          festgelegten einfachen Regeln abläuft.
        </p>
        <div className="d-flex gap-3 flex-wrap mb-3 px-2">
          <img alt="Game of Live App" className="project__image project__image--square"
               src='/projects/gameoflife/screen1.png' onClick={() => openLightboxOnSlide(1)}/>
        </div>
        <FsLightbox
          toggler={lightboxController.toggler}
          slide={lightboxController.slide}
          sources={[
            '/projects/gameoflife/screen1.png',
          ]}
        />
        <div className="mt-4 mb-1 mx-2">
          <a href="http://jannisfey.de/gameoflive/" target="_blank" rel="noreferrer"
             className="btn btn btn-outline-info gradient-color"><i
            className="fa-solid fa-gamepad me-1"></i> Hier geht es zum Spiel</a>
        </div>
      </div>
    </div>
  )
}

export default ProjectGameOfLife;
