import React, { useState } from 'react';

import './Photos.scss';
import FsLightbox from "fslightbox-react";

function Photos() {

  const filenames = [
    "4979844157e523528a2670.87180807.jpg",
    "7679520357e51aa742dac8.87845499.jpg",
    "12863327957e523f0e8ba77.10670372.jpg",
    "13450717857e523d25c8e95.14142679.jpg",
    "14268782257e51f4d951b28.66179173.jpg",
    "16321353757e52350c8a743.69616881.jpg",
    "18071047857e51cd4465183.95730231.jpg",
    "20279979057e516cf0d6179.71934722.jpg",
    "24665578757e522079f4354.55107588.jpg",
    "26594173357e523f33b7476.25693257.jpg",
    "28045410157e51a18d67587.01183211.jpg",
    "31191424057e5234603d144.26859832.jpg",
    "40334381757e51c63b91c72.37934674.jpg",
    "50462595057e51f5ba0bd20.55278408.jpg",
    "60397235157e5225e3ea877.03136233.jpg",
    "67377146357e525178f1cc6.59132218.jpg",
    "67718620357e5226aa13535.84124282.jpg",
    "67895260257e51cc49d5ef2.20705924.jpg",
    "74415335257e519e8d34f00.56747019.jpg",
    "78804252557e51fc01d6786.66010621.jpg",
    "82756459757e516fa754736.10204593.jpg",
    "87118009957e51a2beaee69.94527408.jpg",
    "89898764157e51cf1857594.83561638.jpg",
    "93414811057e51b433294c1.19925659.jpg",
    "94235662157e52285ac2471.41327830.jpg",
    "96008284457e51f8c9af064.00296504.jpg",
    "96162675457e51fcf895f33.23185061.jpg",
    "99971240057e51ae10086e7.35981365.jpg",
    "102436340357e51f1691e492.56200587.jpg",
    "106568908657e5238acf14b9.32146773.jpg",
    "107875874657e51f61118793.94190031.jpg",
    "108581526757e516fd30ae67.09748958.jpg",
    "112354375757e51ba286ced3.41662466.jpg",
    "120084965757e51cc5e84d52.06696550.jpg",
    "123582709157e51b3a503835.48085889.jpg",
    "124516481257e51b0f3bff17.77468708.jpg",
    "125020820057e51cf5d9d126.65243691.jpg",
    "127897985657e51f74c15843.54263493.jpg",
    "128497968657e5237699d128.81933594.jpg",
    "132268844157e5227ff1d958.18800098.jpg",
    "132319241857e51f225a7397.33734316.jpg",
    "133792704657e522055fac87.56345999.jpg",
    "135818215757e51a10e15a79.77899345.jpg",
    "140037822057e51abfec2d85.91693261.jpg",
    "142961153057e52406047442.29608254.jpg",
    "149634596557e5239d416c33.84641525.jpg",
    "152527200957e51c814e3108.74000250.jpg",
    "160105287357e51c4a483f73.98788847.jpg",
    "163501463857e51b7792a772.22794538.jpg",
    "164165268457e5247b8c1ef1.45673480.jpg",
    "166363984157e5224e7966e0.20467328.jpg",
    "167178703757e516db0499e1.44559413.jpg",
    "167432966557e51ae8940517.68607494.jpg",
    "170064731457e516e029d853.55170197.jpg",
    "172019570257e51cac142b29.47491716.jpg",
    "172528955957e5223d8a7635.43186851.jpg",
    "172856548957e51a0e40fe45.54538028.jpg",
    "178733880357e5220aa93ed7.58217691.jpg",
    "179258713757e522095413a0.78930183.jpg",
    "179526595257e51f6894a8a3.28520299.jpg",
    "180830353057e51cf93ab727.60493170.jpg",
    "181165264857e51cb1c84d02.50910436.jpg",
    "181919332757e52396c5cf78.70541734.jpg",
    "182991756257e51708087c37.78384226.jpg",
    "185693001757e52305a47ba6.96179391.jpg",
    "194956239457e51a0c022195.68169229.jpg",
    "196628431757e522268510d1.53917596.jpg",
    "202043259357e51fcd70a337.03704135.jpg",
    "204972114457e51b85c21384.38081948.jpg",
    "206498950557e51b558fa091.24079792.jpg",
    "210101710557e52246dfcc82.44467897.jpg",
    "212427964157e51f8ac88423.31047127.jpg",
  ]

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(index: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index
    });
  }

  return (
    <div id="photos" className="photos col-lg-8 mx-auto">
      <h1 className="fw-bold mb-4 ms-3">Meine besten Fotos</h1>

      <div className="d-flex flex-wrap justify-content-center gap-3">
        {
          filenames.map((filename: string, index: number) => {
            return <div key={filename}
                        style={{backgroundImage: `url(/photos/thumbnails/${filename}`}}
                        className="photos__thumbnail"
                        onClick={() => openLightboxOnSlide(index + 1)}
            />
          })
        }
      </div>

      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={filenames.map((filename: string) => {
          return `/photos/${filename}`;
        })}
      />


    </div>
  );
}

export default Photos;
