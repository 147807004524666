import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function ProjectCircles() {
  const [open, setOpen] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });

  function openLightboxOnSlide(index: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index
    });
  }

  return (
    <div className="project rounded-5 border shadow p-4 mt-3 mb-5">
      <h2>Circles</h2>
      <p>Circles ist ein kleines Spiel, basiert auf dem Traveling Salesman Problem, bei dem man aus mehreren Punkten
        den kürzesten Kreis bilden muss.</p>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-1 flex-wrap align-items-center">
          <span className="badge rounded-pill bg-dark">ANDROID</span>
          <span className="badge rounded-pill bg-dark">JAVA</span>
          <span className="badge rounded-pill bg-dark">XML</span>
          <span className="badge rounded-pill bg-dark">REST</span>
        </div>
        <div className={open ? "project__dropdown-button project__dropdown-button--open" : "project__dropdown-button"}
             onClick={() => setOpen(!open)}>
          <i className="fa-solid fa-angle-down fa-2xl"></i>
        </div>
      </div>
      <div className={open ? "project__collapsed project__collapsed--open" : "project__collapsed"}>
        <div className="mt-3 mb-0">
          <ul>
            <li>3 verschiedene Schwierigkeitsstufen + Zufalls Modus</li>
            <li>Zufallsgenerierte Level</li>
            <li>Online Highscore</li>
            <li>Einfache Steuerung (tippen oder ziehen)</li>
            <li>Einstellungen für Sound, Vibration und Spielername</li>
          </ul>
          <div className="d-flex gap-3 flex-wrap mb-3 px-2">
            <img alt="Circles App" className="project__image" src='/projects/circles/device1.png'
                 onClick={() => openLightboxOnSlide(1)}/>
            <img alt="Circles App" className="project__image" src='/projects/circles/device2.png'
                 onClick={() => openLightboxOnSlide(2)}/>
            <img alt="Circles App" className="project__image" src='/projects/circles/device3.png'
                 onClick={() => openLightboxOnSlide(3)}/>
            <img alt="Circles App" className="project__image" src='/projects/circles/device4.png'
                 onClick={() => openLightboxOnSlide(4)}/>
            <img alt="Circles App" className="project__image" src='/projects/circles/device5.png'
                 onClick={() => openLightboxOnSlide(5)}/>
            <img alt="Circles App" className="project__image" src='/projects/circles/device6.png'
                 onClick={() => openLightboxOnSlide(6)}/>
          </div>
          <FsLightbox
            toggler={lightboxController.toggler}
            slide={lightboxController.slide}
            sources={[
              '/projects/circles/device1.png',
              '/projects/circles/device2.png',
              '/projects/circles/device3.png',
              '/projects/circles/device4.png',
              '/projects/circles/device5.png',
              '/projects/circles/device6.png',
            ]}
          />

          <a className="px-2"
             href="https://play.google.com/store/apps/details?id=de.feyweb.jannis.circles&amp;hl=de&amp;utm_source=global_co&amp;utm_medium=prtnr&amp;utm_content=Mar2515&amp;utm_campaign=PartBadge&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img alt="Jetzt bei Google Play"
                 src="https://play.google.com/intl/en_us/badges/images/generic/de_badge_web_generic.png" height="75px"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ProjectCircles;
