import React from 'react';

import './About.scss';

function About() {
  return (
    <div id="about" className="about rounded-5 border resposive-shadow p-4 col-lg-8 my-5 mx-auto">
      <div className="row">
        <div className="col-sm-4 d-flex flex-column justify-content-center">
          <img alt="Portait von Jannis Fey" className="rounded-5 w-100" src="/about/portrait.png"/></div>
        <div className="col-sm-8 d-flex flex-column justify-content-center pt-5 pb-1 pt-sm-1 px-md-5">
          <h1 className="fw-bold">Das bin ich</h1>
          <p>Hi, ich freue
            mich dass du es auf meine Website geschafft
            hast. Hier findes du ein paar Fakten über mich, meine Projekte sowie eine Auswahl meiner besten
            Fotos.</p>
          <div className="d-flex gap-4 mt-3 justify-content-between justify-content-md-start">
            <a rel="noreferrer" className="xing" target="_blank" href="https://www.xing.com/profile/Jannis_Fey2"><i
              className="fa-brands fa-xing fa-2xl"/></a>
            <a rel="noreferrer" className="linkedin" target="_blank"
               href="src/about/About"><i className="fa-brands fa-linkedin-in fa-2xl"/></a>
            <a rel="noreferrer" className="instagram" target="_blank" href="https://www.instagram.com/jannisfey"><i
              className="fa-brands fa-instagram  fa-2xl"/></a>
            <a rel="noreferrer" className="facebook" target="_blank" href="https://www.facebook.com/jannisfey"><i
              className="fa-brands fa-facebook  fa-2xl"/></a>
            <a rel="noreferrer" className="mail" target="_blank" href="mailto:jannisfey@googlemail.com"><i
              className="fa-solid fa-at fa-2xl"/></a>
          </div>

        </div>
      </div>
    </div>
  );
}

export default About;
