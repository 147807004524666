import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAL_l4MN2LbT_1mJLGQ_sBJI2uYHM9t-9o",
  authDomain: "jannisfey-2022.firebaseapp.com",
  projectId: "jannisfey-2022",
  storageBucket: "jannisfey-2022.appspot.com",
  messagingSenderId: "624874773226",
  appId: "1:624874773226:web:87e3031231e23665e39593"
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
