import React from 'react';

import './App.scss';
import JumboTron from "./jumbotron/JumboTron";
import About from "./about/About";
import Projects from "./projects/Projects";
import Gallery from "./photos/Photos";
import Impress from "./impress/Impress";


function App() {
  return (
    <div className="app container mb-5">
      <JumboTron/>
      <About/>
      <Projects/>
      <Gallery/>
      <Impress/>
    </div>
  );
}

export default App;
