import React from 'react';
import './Projects.scss';
import ProjectCircles from "./ProjectCircles";
import ProjectPakby from "./ProjectPakby";
import ProjectCastlepath from "./ProjectCastlepath";
import ProjectGameOfLife from "./ProjectGameOfLife";

function Projects() {

  return (
    <div id="projects" className="projects col-lg-8 mx-auto">
      <h1 className="fw-bold mt-5 mb-4 ms-3">Meine Projekte</h1>
      <ProjectCircles/>
      <ProjectPakby/>
      <ProjectCastlepath/>
      <ProjectGameOfLife/>
    </div>
  );
}

export default Projects;
